import { CompetitionData } from "./types";

const mockCompetitionData: CompetitionData = {
    pk: "gavinepotter@gmail.com", // <- the partition key in the database - this can be thought of as the ownerId
    rk: "Super Saturday 2024", // <- the row key of the database - this can be thought of as teh tournament name
    owner: "gavinepotter@gmail.com",
    // the pk and rk together create a universally unique identifier
    fixtures: [
        {
            id: 0,
            homeTeam: "Ireland",
            awayTeam: "France",
            homeEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/3EKZ6UGbiRXYLt7y51LAPk/119c7b36d606a74c2cabdc8e37b5d46f/Scotland_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriText: "Ireland Rugby Logo",
            awayEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/3EKZ6UGbiRXYLt7y51LAPk/119c7b36d606a74c2cabdc8e37b5d46f/Scotland_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriText: "France Rugby Logo",
            homeEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/6nCVYHpMrfZ0yzoVLn8Vpe/25e6d93bdf31b52904eaded3c58ade81/Ireland_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriNegativeText: "Ireland Rugby Logo",
            awayEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/2hayBHU7r5VQNm21XqOA5d/4c9920200495a5d22974b8b5bd950867/France_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriNegativeText: "France Rugby Logo",
            homeScore: 5,
            awayScore: 13,
            startTime: "14:00",
            channel: "Amazon",
            active: false,
            fullTime: true,
        },
        {
            id: 1,
            homeTeam: "Scotland",
            awayTeam: "Wales",
            homeEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/3EKZ6UGbiRXYLt7y51LAPk/119c7b36d606a74c2cabdc8e37b5d46f/Scotland_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriText: "Scotland Rugby Logo",
            awayEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/1qf7IcztL16NQJSrcQ25EX/226b311c0c5ca6b308618ca48c235213/Wales_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriText: "Wales Rugby Logo",
            homeEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/6nCVYHpMrfZ0yzoVLn8Vpe/25e6d93bdf31b52904eaded3c58ade81/Ireland_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriNegativeText: "Scotland Rugby Logo",
            awayEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/6nCVYHpMrfZ0yzoVLn8Vpe/25e6d93bdf31b52904eaded3c58ade81/Ireland_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriNegativeText: "Wales Rugby Logo",
            homeScore: 18,
            awayScore: 10,
            startTime: "16:00",
            channel: "ITV",
            active: true,
            fullTime: false,
        },
        {
            id: 2,
            homeTeam: "England",
            awayTeam: "Italy",
            homeEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/1qf7IcztL16NQJSrcQ25EX/226b311c0c5ca6b308618ca48c235213/Wales_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriText: "England Rugby Logo",
            awayEmblemUri:
        "https://images.ctfassets.net/uiu4umqyl5b5/1qf7IcztL16NQJSrcQ25EX/226b311c0c5ca6b308618ca48c235213/Wales_-_Colour.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriText: "Italy Rugby Logo",
            homeEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/2hayBHU7r5VQNm21XqOA5d/4c9920200495a5d22974b8b5bd950867/France_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            homeEmblemUriNegativeText: "England Rugby Logo",
            awayEmblemUriNegative:
        "https://images.ctfassets.net/uiu4umqyl5b5/2hayBHU7r5VQNm21XqOA5d/4c9920200495a5d22974b8b5bd950867/France_-_Negative.png?fm=webp&f=center&w=100&h=100&q=75",
            awayEmblemUriNegativeText: "Italy Rugby Logo",
            startTime: "15:00",
            channel: "BBC 1",
            active: false,
            fullTime: false,
        },
    ],
    players: [
        {
            id: "599699ae-f666-435a-9996-986fcc6a8861",
            email: "steffibaby@gmail.com",
            name: "Steff",
            score: 5,
            move: "up",
            predictions: [
                {
                    fixtureId: 0,
                    homePrediction: 6,
                    awayPrediction: 14,
                },
                {
                    fixtureId: 1,
                    homePrediction: 16,
                    awayPrediction: 8,
                },
                {
                    fixtureId: 2,
                    homePrediction: 15,
                    awayPrediction: 7,
                },
            ],
        },
        {
            id: "599699ae-f666-435a-9996-986fcc6a8862",
            email: "gavinepotter@gmail.com",
            name: "Gav Gav",
            move: "down",
            score: 4,
            predictions: [
                {
                    fixtureId: 0,
                    homePrediction: 7,
                    awayPrediction: 15,
                },
                {
                    fixtureId: 1,
                    homePrediction: 17,
                    awayPrediction: 9,
                },
                {
                    fixtureId: 2,
                    homePrediction: 0,
                    awayPrediction: 0,
                },
            ],
        },
    ],
};

const mockData = { data: mockCompetitionData };
const mockCompetitions = {
    data: [
        {
            pk: "gavinepotter@gmail.com",
            rk: "sample-guid-1",
            competitionOwner: "gavinepotter@gmail.com",
            title: "Super Saturday 2024",
        },
        {
            pk: "gavinepotter@gmail.com",
            rk: "sample-guid-2",
            competitionOwner: "gavinepotter@gmail.com",
            title: "Super Saturday 2023",
        },
    ],
};

export { mockData, mockCompetitions };

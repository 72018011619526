import React from "react";
import { Player } from "./types";

const Leaderboard = ({ players }: { players: Player[] }) => (
    <div className="col-md-6 container text-center p-md-5 pt-5 pb-0">
        <h2 className="pb-3">Leaderboard</h2>
        <table className="table mb-0">
            <thead>
                <tr>
                    <th>Player</th>
                    <th>Points</th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                {(players as Player[])
                    .filter(p => p.name !== "TBA")
                    .map(player => (
                        <tr key={player.email}>
                            <td>{player.name}</td>
                            <td>{player.score}</td>
                            <td>
                                <i className={`fa-solid fa-caret-${player.move}`} />
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    </div>
);

export { Leaderboard };

import React from "react";
import { Button, Row } from "react-bootstrap";
import { AuthenticationOptionsProps } from "./types";

const AuthenticationOptions = ({ workflow, updateWorkflow }: AuthenticationOptionsProps) => (
    <Row className="m-1">
        <Button
            variant="outline-success"
            className="mb-1 mt-1 btn-lg"
            data-testid="register"
            onClick={() => updateWorkflow(workflow === "login" ? "register" : "login")}
        >
            {workflow === "register" ? "I already have an account" : "Register a new account"}
        </Button>
        <br />
        {workflow !== "register" && (
            <Button data-testid="forgot" variant="link" className="mb-1" onClick={() => updateWorkflow("recover")}>
                Forgot Password
            </Button>
        )}
    </Row>
);

export { AuthenticationOptions };

/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LogOut } from "./auth/LogOut";
import { Authentication } from "./auth/Authentication";
// import { Crud } from "./crud/Crud";
import { useAuthContext } from "./context/auth-context";
import { UserAuthenticated } from "./context/types";
import { useAuthentication } from "./auth/useAuthentication";
import { Dashboard } from "./dashboard/Dashboard";
import logo from "./img/logo.png";

const App = () => {
    const { authStatus } = useAuthContext();
    const { refreshLogin } = useAuthentication();

    useEffect(() => {
        if (authStatus === UserAuthenticated.UNKNOWN) {
            refreshLogin();
        }
        // eslint-disable-next-line no-restricted-globals
        // setTimeout(() => location.reload(), 60000);
    });

    return (
        <>
            <header className="text-center pb-5">
                <div className="content">
                    <img src={logo} alt="Guinness Six Nations Logo" />
                    <h1 className="display-3">Super Saturday 2024 </h1>
                </div>
            </header>

            {authStatus === UserAuthenticated.UNKNOWN ? (
                "Authenticating ..."
            ) : authStatus === UserAuthenticated.YES ? (
                <Dashboard />
            ) : (
                <Container>
                    <Row>
                        <Col className="col-lg-6 mt-5 offset-lg-3">
                            <Authentication />
                        </Col>
                    </Row>
                </Container>
            )}

            <footer className="container-fluid text-center pb-5 pt-5 d-grid d-md-block">
                {authStatus === UserAuthenticated.YES && <LogOut />}
            </footer>
        </>
    );
};

export { App };

import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { Email } from "./Email";
import { Password } from "./Password";
import { Submit } from "./Submit";
import { LoginProps } from "./types";

const Register = ({
    email,
    updateEmail,
    onSubmit,
}: {
    email: string;
    updateEmail: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (props: LoginProps) => void;
}) => {
    const [validated, setValidated] = useState(false);
    const [password, updatePassword] = useState<string>("");

    const message = "Please enter your email address and choose a password to register.";

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            onSubmit({ email, password });
        }
        setValidated(true);

        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 500);
    };

    return (
        <>
            <p>{message}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Email email={email} updateEmail={updateEmail} />
                <Password password={password} updatePassword={updatePassword} />
                <Submit label="Register" />
            </Form>
        </>
    );
};

export { Register };

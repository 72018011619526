import React from "react";
import { Login } from "./Login";
import { Register } from "./Register";
import { Recover } from "./Recover";
import { AuthenticationOptions } from "./AuthenticationOptions";
import { Reset } from "./Reset";
import { useAuthentication } from "./useAuthentication";

const Authentication = () => {
    const {
        register, login, recover, reset, email, updateEmail, workflow, updateWorkflow,
    } = useAuthentication();

    return (
        <>
            {workflow === "register" && <Register onSubmit={register} email={email} updateEmail={updateEmail} />}
            {workflow === "recover" && <Recover onSubmit={recover} email={email} updateEmail={updateEmail} />}
            {workflow === "reset" && <Reset onSubmit={reset} />}
            {workflow === "login" && <Login email={email} updateEmail={updateEmail} onSubmit={login} />}
            {workflow !== "recover" && workflow !== "reset" && (
                <AuthenticationOptions workflow={workflow} updateWorkflow={updateWorkflow} />
            )}
        </>
    );
};

export { Authentication };

const prodConfig = {
    apiBaseUrl: "https://1c5rsrd2bc.execute-api.eu-west-1.amazonaws.com/prod",
    defaultLandingPage: "login",
    auth:{
        region: "eu-west-1",
        clientId : "2bibjtnaq837teincnns6s7p69",
        flow: "USER_PASSWORD_AUTH"
    }
}

const localConfig = {
    apiBaseUrl: "http://localhost:5001",
    defaultLandingPage: "login",
    auth:{
        region: "eu-west-1",
        clientId : "2bibjtnaq837teincnns6s7p69",
        flow: "USER_PASSWORD_AUTH"
    }
}

const config = process.env.REACT_APP_IS_LOCAL ? localConfig : prodConfig;

export { config }
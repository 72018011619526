import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { EmailProps } from "./types";

const Email = ({ email, updateEmail }: EmailProps) => {
    const { Control } = Form;
    const { Feedback } = Control;
    return (
        <InputGroup>
            <FloatingLabel label="Email address" className="mb-3">
                <Control
                    data-testid="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={({ target: { value } }) => updateEmail(value)}
                    required
                />
                <Feedback type="invalid" tooltip>
                    Please enter a valid email address.
                </Feedback>
            </FloatingLabel>
        </InputGroup>
    );
};

export { Email };

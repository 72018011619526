import { CognitoIdentityServiceProvider } from "aws-sdk";
import { Dispatch, SetStateAction } from "react";

type Config = {
    apiBaseUrl: string;
    defaultLandingPage: string;
    auth: {
        region: string;
        clientId: string;
        flow: string;
    };
};

type LoginStatus = UserAuthenticated.YES | UserAuthenticated.NO | UserAuthenticated.UNKNOWN;

type TokenStore = {
    accessToken: string;
    idToken: string;
    refreshToken: string;
};

type AuthContextType = {
    cognitoServiceProvider: CognitoIdentityServiceProvider;
    authStatus: LoginStatus;
    tokenStore: TokenStore;
    email: string;
    updateEmail: any;
    updateAuthStatus: Dispatch<SetStateAction<LoginStatus>> | (() => {});
    updateTokenStore: Dispatch<SetStateAction<TokenStore>> | (() => {});
    updateRefreshTokenCookie: (token: string) => void;
};

export enum UserAuthenticated {
    YES,
    NO,
    UNKNOWN,
}

export type { AuthContextType, Config, LoginStatus };
